* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
}
/*# sourceMappingURL=index.6cbb94dc.css.map */
